/*
 * CB Glyph Mixins
 */

@use "sass:string";

@function icon($character) {
  @return string.unquote('"') + string.unquote(string.insert($character, "\\", 1)) +
    string.unquote('"');
}

@mixin apricot-font($name) {
  font-family: $name;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  @include font-smoothing();
}

// @mixin glyph-color($color) {
//   color: $color;

//   &.cb-glyph-numeric {
//     border-color: $color;

//     &.cb-glyph-circular {
//       color: $white-color;
//       background-color: $color;
//       border-color: $color;
//     }
//   }
// }

@mixin glyph-multi-content-color($color1, $color2, $bk: null) {
  @if $bk {
    & {
      background-color: $bk;
    }
  }

  &::before {
    color: $color1;
  }

  &::after {
    color: $color2;
  }
}

@mixin pseudo-content($before: null, $after: null, $imp: null) {
  &::before {
    content: icon($before);
  }

  @if $after {
    &::after {
      content: icon($after);
    }
  }
}

@mixin pseudo-content-important($before: null, $after: null, $imp: null) {
  &::before {
    content: icon($before) !important;
  }

  @if $after {
    &::after {
      content: icon($after) !important;
    }
  }
}

@mixin border-box() {
  box-sizing: border-box;
}

@mixin print-glyph-numeric() {
  /* Glyphs */
  .cb-glyph-numeric {
    color: $black !important;
    border-color: $black !important;

    &.cb-glyph-circular {
      &::before {
        color: $black !important;
      }

      & {
        color: $black !important;
      }

      @each $color, $map in $color-themes {
        &[class*="#{'cb-' + $color}"] {
          color: $black !important;
        }
      }
    }
  }
}

@mixin print-icon-numeric() {
  /* Glyphs */
  .cb-icon-numeric {
    color: $black !important;
    border-color: $black !important;

    &.cb-icon-circular {
      &::before {
        color: $black !important;
      }

      & {
        color: $black !important;
      }

      @each $color, $map in $color-themes {
        &[class*="#{'cb-' + $color}"] {
          color: $black !important;
        }
      }
    }
  }
}
